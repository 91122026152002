/*------------------------------------*\
  #COMPONENTS-SPEAKERS
\*------------------------------------*/

.c-speakers {
  margin-bottom: 4rem;
}

.c-speakers__content {
  bottom: 0;
  color: var(--color-white);
  padding: 1rem;
  position: absolute;
  width: 100%;
  z-index: 4;

  @media(--breakpoint-40) {
    padding: 1rem 0 1rem 1rem;
  }
}

.c-speakers__img {
  background-position: 50% 15%;
  background-size: cover;
  content: "";
  height: 100%;
  width: 100%;
  transition: transform .2s ease-out;

  @media(--breakpoint-30) {
    background-position: 50% 50%;
    background-size: cover;
  }
}

.c-speakers__item {
  height: 400px;
  outline: none;
  position: relative;

  @media(--breakpoint-30) {
    margin-top: 4rem;

    &:hover,
    &:focus {

      & .c-speakers__img {
        transform: rotate(4deg) scale(1.1);
      }

      & .c-speakers__speaker {
        opacity: 1;
      }

      &::before {
        transform: rotate(-5deg);
        -webkit-backface-visibility: hidden;
      }
    }

    &:focus {

      &::before {
        opacity: .9;
      }
    }
  }

  &::after {
    background: var(--color-white);
    bottom: calc(-1*var(--space-40));
    box-shadow: 0 0 64px rgba(0,0,0, .15);
    content: "";
    display: none;
    height: 75%;
    left: var(--space-40);
    position: absolute;
    width: 100%;
    z-index: -1;

    @media(--breakpoint-30) {
      display: block;
    }
  }

  &:nth-child(3n+1) {

    @media(--breakpoint-30) {
      margin-top: 1rem;

      &:hover,
      &:focus {
        & .c-speakers__img {
          transform: rotate(-6deg) scale(1.1);
        }

        &::before {
          transform: rotate(4.5deg);
        }
      }
    }
  }

  &:nth-child(3n+1):after {
    width: 100%;
    height: 75%;
    left: var(--space-40);
    top: var(--space-40);
  }

  &:nth-child(3n+3) {

    @media(--breakpoint-30) {
      margin-top: 0;

      &:hover,
      &:focus {
        & .c-speakers__img {
          transform: rotate(-2deg) scale(1.1);
        }

        &::before {
          transform: rotate(6deg);
        }
      }
    }
  }

  &:nth-child(3n+3):after {
    width: 100%;
    height: 85%;
    left: auto;
    right: var(--space-40);
    bottom: var(--space-40);
  }

  &::before {
    background-image: linear-gradient(135deg, var(--color-secondary-50) 0%, #5F2BD5 100%);
    content: "";
    display: block;
    filter: saturate(1.25);
    height: 100%;
    position: absolute;
    opacity: .7;
    width: 100%;
    transition: transform .2s ease-out;
    z-index: 3;
  }
}

.c-speakers__item--host {

  &::before {
    background-image: linear-gradient(135deg, var(--color-primary-30) 0%, var(--color-primary-50) 100%);
  }
}

.c-speakers__link {
  color: var(--color-secondary-50);
  font-size: var(--font-size-50);
  font-weight: 500;
  text-decoration: none;
}

.c-speakers__speaker {
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 12px;
  line-height: var(--line-height-20);
  opacity: .6;
  padding-right: 4rem;
  text-transform: uppercase;

  @media(--breakpoint-20) {
    font-size: 1rem;
  }

  @media(--breakpoint-40) {
    font-size: 1.25rem;
  }
}

.c-speakers__subject {
  font-size: 2.5rem;
  line-height: 3rem;
  text-shadow: 0 2px 10px rgba(0,0,0,.25);
}

.c-speakers__subject--small {
    font-size: 2rem;
    line-height: 2.5rem;
}
