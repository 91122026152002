/*------------------------------------*\
  #COMPONENTS-HEADING
\*------------------------------------*/

.c-heading {
  color: var(--color-primary-50);
  font-family: var(--font-family-heading);
  font-size: var(--font-size-60);
  font-weight: 600;
  line-height: var(--line-height-default);
}

/* Sizes */
.c-heading--small {
  font-size: var(--font-size-50);
}

/* Default */
.c-heading--medium {

}

.c-heading--large {
  font-size: var(--font-size-70);
}

.c-heading--huge {
  font-size: var(--font-size-70);

  @media(--breakpoint-30) {
    font-size: var(--font-size-80);
  }
}
