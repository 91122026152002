/*------------------------------------*\
  #COMPONENTS-PARAGRAPH
\*------------------------------------*/

.c-paragraph--large,
.c-paragraph--lead,
.c-paragraph--medium {
  color: #9b91a6;
  max-width: 65ch;
}

.c-paragraph--large,
.c-paragraph--lead {
  font-weight: 400;
}

.c-paragraph--large {
  font-size: var(--font-size-60);
  line-height: var(--line-height-30);
  max-width: 700px;
}

.c-paragraph--lead {
  font-size: 1.5rem;
  margin-bottom: 24px;
}

.c-paragraph--medium {
  font-size: var(--font-size-50);
}

.u-text-align-center .c-paragraph--medium {
  max-width: none;
}

.c-paragraph--small {
  color: var(--color-neutral-50);
  font-size: var(--font-size-20);
}
