/*------------------------------------*\
  #COMPONENTS-SOCIALS
\*------------------------------------*/

.c-socials__link {
  border: 0;
  color: var(--color-secondary-50);
  font-size: var(--font-size-50);
  font-weight: 500;
  margin-right: 2rem;
  outline: none;
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--color-primary-50);
    text-decoration: underline;
  }

  &:visited {
    color: var(--color-secondary-50);
  }
}

.c-socials__icon {
  display: inline-block;
  fill: currentColor;
  height: 2.5rem;
  margin-right: .25rem;
  vertical-align: middle;
  width: 2.5rem;
}
