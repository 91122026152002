/*------------------------------------*\
  #OBJECTS-PARTNERS
\*------------------------------------*/

.c-partners {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  list-style: none;
  lost-utility: clearfix;
  margin-bottom: 4rem;

  @media (--breakpoint-30) {
    background: none;
  }
}

.c-partners__item {
  display: flex;
  flex-flow: column nowrap;
  line-height: 2.25rem;
  margin: 1.5rem 3rem;
}

.c-partners__link {
  border: 0;
  display: block;
  text-decoration: none;
  transition: filter .3s ease-out;
}

.c-partners__logo {
  display: block;
  transition: all .2s ease-out;

  &:hover {
    transform: scale(1.1);
  }
}

.c-partners__heading {
  border-bottom: 1px solid #efefef;
  color: #AAA1B5;
  font-size: var(--font-size-30);
  font-weight: 600;
  letter-spacing: 8px;
  margin-bottom: .5rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: .5rem;
  text-transform: uppercase;
  width: 80%;
}

.c-partners--gold {

  & .c-partners__logo {
    max-height: 2.5rem;
    max-width: 5rem;

    @media (--breakpoint-10) {
      max-height: 3rem;
      max-width: 8rem;
    }

    @media (--breakpoint-30) {
      margin: 1rem;
      max-height: 4rem;
      max-width: 8rem;
    }

    @media (--breakpoint-50) {
      margin: 1.25rem 2rem;
      max-height: 4.5rem;
      max-width: 11rem;
    }
  }
}

.c-partners--silver {

  & .c-partners__logo {
    max-height: 2.125rem;
    max-width: 5rem;

    @media (--breakpoint-10) {
      max-height: 2.5rem;
      max-width: 8rem;
    }

    @media (--breakpoint-30) {
      max-height: 3.125em;
      max-width: 9rem;
    }
  }
}

.c-partners--bronze {

  & .c-partners__logo {
    max-height: 1.75rem;
    max-width: 5rem;

    @media (--breakpoint-10) {
      max-height: 2em;
      max-width: 8rem;
    }

    @media (--breakpoint-30) {
      max-height: 2.5em;
      max-width: 8rem;
    }
  }
}
