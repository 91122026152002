/*------------------------------------*\
  #COMPONENTS-NAVIGATION
\*------------------------------------*/

.c-navigation {
  background: white;
  box-shadow: 0 1px 6px 0 rgba(0,0,0, .1);
  list-style: none;
  padding: 1.5rem .5rem;
  position: fixed;
  margin-left: -1rem;
  margin-right: -1rem;
  top: 0;
  width: 100%;
  z-index: 1000;

  @media(--breakpoint-20) {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  @media(--breakpoint-30) {
    background: none;
    box-shadow: none;
    float: right;
    margin-left: 0;
    margin-top: 1rem;
    padding: 0;
    position: static;
    width: auto;
  }
}

.c-navigation__item {
  display: inline;

  &:last-child {
    display: block;
    margin-top: 1rem;

    @media(--breakpoint-10) {
      display: inline;
    }

    @media(--breakpoint-30) {
      margin-top: 0;
    }
  }
}

.c-navigation__link {
  color: #AAA1B5;
  font-size: var(--font-size-30);
  font-weight: 600;
  outline: none;
  margin: 0 .5rem;
  text-decoration: none;
  text-transform: lowercase;

  &:hover,
  &:focus {
    color: var(--color-primary-50);
    border-bottom: 2px dashed var(--color-primary-50);
  }

  &:active {
    /* TODO Add CSS style */
  }

  @media(--breakpoint-10) {
    font-size: var(--font-size-40);
  }

  @media(--breakpoint-20) {
    margin: 0 .5rem;
  }

  @media(--breakpoint-30) {
    margin: 0 1rem;
  }
}

.c-navigation__link--active {
  color: var(--color-primary-50);
}
