/*------------------------------------*\
  #COMPONENTS-SCHEDULE
\*------------------------------------*/

@keyframes spin { 100% { transform:rotate(360deg); } }

.c-schedule-container {
  background: var(--color-white);
  box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
  max-width: 47.5rem;
}

.c-schedule-row {
  align-items: center;
  color: var(--color-neutral-90);
  display: flex;
  font-size: var(--font-size-30);
  line-height: 1.5rem;
  max-width: 47.5rem;
  padding: .5rem 1rem;
  text-decoration: none;

  @media (--breakpoint-10) {
    font-size: var(--font-size-40);
    padding: 1rem 1.5rem;
  }

  &--naked {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}

a.c-schedule-row {
  outline: none;

  &:hover {
    background: #F5F4F5;
    color: var(--color-neutral-90);

    & .c-schedule-time {
      color: var(--color-neutral-90);
    }
  }

  &:focus,
  &:active {
    box-shadow: inset 6px 0 0 0 var(--color-primary-50);
  }

  &:focus {

    & .c-schedule-time {
      color: var(--color-neutral-90);
    }

      & .c-schedule-image:before {
      left: 0;
      opacity: 0;
    }
  }
}

.c-schedule-row--border {
  border-bottom: .125rem dotted var(--color-neutral-10);
  padding: .5rem 1rem .375rem;

  @media (--breakpoint-10) {
    font-size: var(--font-size-40);
    padding: 1rem 1.5rem .875rem;
  }

  &:hover {
    border-bottom-color: #F5F4F5;
  }

  & .c-schedule-time {
    color: var(--color-neutral-90);
  }
}

.c-schedule-row--naked {

  & .c-schedule-time {
    color: #776986;
  }

  & .c-schedule-image--empty:hover {
    animation-play-state: running;
  }

  & + .c-schedule-row--naked {
    margin-top: -1rem;
  }
}

.c-schedule-time {
  color: #80708F;
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-40);
  text-decoration: none;

  @media (--breakpoint-10) {
    font-size: var(--font-size-40);
  }
}

.c-schedule-image {
  animation: spin 30s linear infinite;
  animation-play-state: paused;
  background: var(--color-neutral-10);
  border-radius: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 1rem;
  height: 50px;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  width: 50px;

  @media (--breakpoint-10) {
    height: 65px;
    margin: 0 1.5rem;
    width: 65px;
  }

  &:before {
    transition: .25s;
    background-image: linear-gradient(135deg, var(--color-secondary-50) 0%, #5F2BD5 100%);
    content: "";
    display: block;
    height: 150%;
    left: -80%;
    opacity: .7;
    position: absolute;
    top: -1rem;
    transform: rotate(-10deg);
    width: 100%;
  }

  & img {
    height: 100%;
    width: 100%;
  }

  &--tba:before {
      background-image: none;
      color: var(--color-primary-50);
      content: "\1F941";
      font-size: 1.5rem;
      font-weight: 600;
      height: 100%;
      left: 0;
      opacity: 1;
      text-align: center;
      top: 30%;
      transform: rotate(0deg);

      @media (--breakpoint-10) {
        font-size: 2rem;
        top: 35%;
      }
  }
}

.c-schedule-image--empty {
  background-image: url('./../../dist/images/refresh.png');
  background-size: cover;

  &:before {
    display: none;
  }
}
