/*------------------------------------*\
  #COMPONENTS-GRID
\*------------------------------------*/

/*
 * Add your own grid items
 */
.c-grid {
  width: 100%;
  display: grid;
  grid-column-gap: var(--space-default);
  grid-row-gap: var(--space-default);

  @media(--breakpoint-30) {
    grid-column-gap: 96px;
    grid-row-gap: var(--space-default);
    margin: var(--space-stack-default);
  }
}

.c-grid__no-gap {
  grid-column-gap: 0;
  grid-row-gap: 0;
  margin: 0;
}

.c-grid--content {

  @media(--breakpoint-30) {
    grid-column-gap: 0;
  }

  @media(--breakpoint-20) {
    grid-template-columns: auto auto;
  }
}

.c-grid--thirds {

  @media(--breakpoint-10) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media(--breakpoint-20) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.c-grid--halves {

  @media(--breakpoint-20) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.c-grid--single {
  margin: auto;
  max-width: 54rem;
}

.c-grid--single-left {
  max-width: 44rem;
}
