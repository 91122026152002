/*------------------------------------*\
  #BASE-PAGE
\*------------------------------------*/

*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  color: var(--color-neutral-90);
  font-family: var(--font-family-body);
  font-weight: var(--font-weight-default);
  font-size: var(--font-size-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  scroll-behavior: smooth;
}


body {
  background: #f5f4f5 url(../images/bg-page.png) repeat-x;
  background-size: 100%;
  height: 100%;
  margin: 8rem 0 0;
  line-height: var(--line-height-default);

  @media(--breakpoint-10) {
    margin: 5rem 0 0;
  }

  @media(--breakpoint-20) {
    margin: 0;
  }
}

small a {
  color: #9b91a6;
}

[tabindex] {
  outline: none;
}
