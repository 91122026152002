/*------------------------------------*\
  #COMPONENTS-BUTTON
\*------------------------------------*/

.c-bar {
  background: linear-gradient(to right, var(--color-primary-70) 0%, var(--color-primary-50) 75%);
  bottom: 0;
  color: var(--color-white);
  font-size: var(--font-size-40);
  font-weight: 500;
  letter-spacing: .5px;
  line-height: 3rem;
  width: 100%;
  z-index: 10000;

  @media(--breakpoint-10) {
    display: block;
  }

  @media(--breakpoint-20) {
    position: fixed;
  }

  & p {
    margin-right: auto;
    padding-right: .5rem;

    @media(--breakpoint-20) {
      margin-bottom: 0;
      padding-right: 1rem;
    }
  }
}
