/*------------------------------------*\
  #UTILITIES-GLOBAL
\*------------------------------------*/

.u-display-none { display: none !important; }

.u-display-block { display: block; }

.u-visibility-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  position: absolute !important;
  overflow: hidden;
  width: 1px;
 }

.u-text-align-left { text-align: left; }

.u-text-align-right { text-align: right; }

.u-text-align-center { text-align: center; }

.u-float-left { float: left; }

.u-float-right { float: right; }

.u-spacing {

  @media (--breakpoint-20) {
    margin-bottom: calc(var(--space-default) * 2);
  }
}

.u-spacing--small {
  margin-bottom: var(--space-10);
}

.u-spacing--none {
  margin-bottom: 0;
}

.u-line-through {
  text-decoration: line-through;
}

.u-nowrap { white-space: nowrap; }
