/*------------------------------------*\
  #COMPONENTS-MAILCHIMP
\*------------------------------------*/

#mc_embed_signup form#mc-embedded-subscribe-form {
  text-align: left;
  margin-bottom: 2.5rem;

  & input {
    border-color: #dad6df;
    border-radius: 0;
    font-family: var(--font-family-body);
    font-size: 1.25rem;
    height: 48px;
    margin-bottom: .5rem;
  }

  & .button {
    background: #F6EBF0;
    border: 2px solid transparent;
    border-radius: 0;
    color: #F2408D;
    font-family: var(--font-family-body);
    font-weight: 600;

    &:hover,
    &:focus {
      background: var(--color-white);
      border: 2px dashed #F6EBF0;
      color: #F2408D;
    }

    &:visited {
      color: #F2408D;
    }

    &:active {
      border: 2px solid #F2408D;
    }
  }
}
