/*------------------------------------*\
  #COMPONENTS-LAYOUT
\*------------------------------------*/

/*
 * Get full width backgrounds around your content
 */
.c-layout {
  clear: both;
}


/*
 * Set up a row for full width styling
 */
.c-layout__row {
  padding: var(--space-50) 0;
}

.c-layout__row--full-height {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 80vmin;
}

.c-layout__row--image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.c-layout__row--primary {
  background: var(--color-primary-50);
  color: var(--color-white);
}

.c-layout__row--white {
  background: var(--color-white);
}

.c-layout__row--icon {


  @media(--breakpoint-50) {
    background-image: url('./../../dist/images/refresh-groningen.svg');
    background-position: right 20% top 50%;
    background-repeat: no-repeat;
    background-size: 33rem;
  }
}

.c-layout__row--skyline {


  @media(--breakpoint-30) {
    background-image: url('./../../dist/images/groningen_skyline.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    padding-bottom: 5rem;
  }

  @media(--breakpoint-40) {
    padding-bottom: 7rem;
  }

  @media(--breakpoint-50) {
    padding-bottom: 9rem;
  }
}

.c-layout__row--line {
  border-bottom: 1px solid var(--color-neutral-10);
}

.c-layout__row--top {
  padding-top: 0;
  padding-bottom: 0;
}



/*
 * Build up your container to get your website centered
 */
.c-layout__container {
  margin: auto;
  max-width: var(--max-width);
  padding: var(--space-inset-default);
  position: relative;

  @media (--breakpoint-20) {
    padding: var(--space-inset-40);
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

.c-layout__container--bar {
  padding: var(--space-inset-40);

  @media (--breakpoint-20) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: var(--space-inset-30) var(--space-inset-40);
  }
}
