.c-header {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: 2, 1fr;
  margin-bottom: 2rem;

  @media(--breakpoint-50) {
    margin: 0 -4rem 2rem;
  }
}

.c-header-image {
  background: var(--color-primary-50);
  background-position: 50% 25%;
  background-size: cover;
  display: grid;
  grid-column: 1 / span 10;
  grid-row: 1;
  margin-bottom: -2rem;
  min-height: 16rem;
  padding: var(--space-inset-50) var(--space-inset-40);
  position: relative;
  z-index: 1;

  @media(--breakpoint-20) {
    background-position: 50% 50%;
    grid-column: 1 / span 5;
    height: 100%;
    margin-top: 2rem;
    padding: var(--space-inset-50);
  }

  &::after {
    background-image: linear-gradient(135deg, var(--color-secondary-50) 0%, #5F2BD5 100%);
    content: "";
    display: block;
    filter: saturate(1.25);
    opacity: .70;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
}

.c-header-heading {
  align-self: end;
  color: var(--color-white);
  font-size: var(--font-size-70);
  font-weight: 600;
  line-height: 3.5rem;
  z-index: 3;

  @media(--breakpoint-30) {
    font-size: var(--font-size-80);
    line-height: 5.5rem;
  }
}

.c-header-content {
  background: var(--color-white);
  box-shadow: 0 0 64px rgba(0,0,0,.15);
  grid-column: 1 / span 10;
  grid-row: 1 / span 2;

  @media(--breakpoint-20) {
    grid-column: 4 / span 7;
    grid-row: 1;
  }
}

.c-header-link {
  border-bottom: 2px dotted #c8a9ed;
  color: var(--color-primary-50);
  font-weight: 600;
  text-decoration: none;

  &:hover,
  &:focus {

  }

  &:visited {
    color: var(--color-primary-50);
  }
}

.c-header-text {
  grid-column: 1 / span 10;
  grid-row: 2;
  padding: var(--space-inset-50) var(--space-inset-40);

  @media(--breakpoint-20) {
    grid-column: 6 / span 5;
    grid-row: 1;
  }

  @media(--breakpoint-30) {
    padding: var(--space-inset-50);
  }

  & del {
    opacity: .5;
  }
}

.c-header-term {
  color: #AAA1B5;
  font-weight: 600;
  margin-bottom: .5rem;
  letter-spacing: 8px;
  text-transform: uppercase;
}

.c-header-definition {
  color: var(--color-primary-50);
  font-size: var(--font-size-50);
  font-weight: 600;
  line-height: var(--line-height-30);
  margin-bottom: 2rem;
  margin-left: 0;
}
