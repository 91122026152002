/*------------------------------------*\
  #COMPONENTS-PANELS
\*------------------------------------*/

.c-panel {
  margin: var(--space-stack-default);
  padding: var(--space-inset-40);
  background: var(--color-neutral-10);
}

.c-panel--neutral {
  background: var(--color-neutral-10);
}

.c-panel--primary {
  background: var(--color-primary-50);
  color: var(--color-white);
}

.c-panel--white {
  background: var(--color-white);
}
