/*------------------------------------*\
  #COMPONENTS-BUTTON
\*------------------------------------*/

.c-button {
  cursor: pointer;
  display: inline-block;
  font-size: var(--font-size-40);
  font-weight: 600;
  line-height: 1.5rem;
  padding: 1rem 2rem;
  text-align: left;
  text-decoration: none;
  vertical-align: center;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

.c-button--secondary {
  background: #F6EBF0;
  border: 2px solid transparent;
  color: #F2408D;
  font-size: var(--font-size-45);

  &:hover,
  &:focus {
    background: var(--color-white);
    border: 2px dashed #F6EBF0;
    color: #F2408D;
  }

  &:visited {
    color: #F2408D;
  }

  &:active {
    border: 2px solid #F2408D;
  }
}

.c-button--diapositve {
  background: var(--color-white);
  border: 2px solid transparent;
  color: #F45D9E;

  &:hover,
  &:focus {
    color: var(--color-primary-50);
  }

  &:visited {
    color: #F45D9E;
  }

  &:active {
    background: transparent;
    border: 2px solid var(--color-white);
    color: var(--color-white);
  }
}
