/*------------------------------------*\
  #COMPONENTS-MEDIA
\*------------------------------------*/

.c-media {
  margin: var(--space-stack-default);
  position: relative;
  text-align: center;
  width: 100%;
}

.c-media__image {
  display: block;
  max-width: 100%;
}

.c-media--overlay {
  overflow: hidden;

  &::after {
    background-image: linear-gradient(135deg, var(--color-secondary-50) 0%, #5F2BD5 100%);
    content: "";
    display: block;
    filter: saturate(1.25);
    opacity: .70;
    position: absolute;
    height: 100%;
    bottom: 0;
    width: 100%;
    z-index: 2;
  }
}

.c-media--overlay--rotate {

  &::after {
    bottom: -100%;
    height: 200%;
    left: 50%;
    transform: rotate(-45deg);
  }
}

.c-media__caption {
  color: var(--color-neutral-50);
  font-size: var(--font-size-20);
}

.c-media--left {

  & .c-media__image {
      width: 100%;
  }

  @media(--breakpoint-10) {
    border-radius: 50%;
    float: left;
    margin-right: 3rem;
    width: 250px;
  }

  @media(--breakpoint-20) {
    width: 350px;
  }
}

.c-media--right {

  & .c-media__image {
      width: 100%;
  }

  @media(--breakpoint-10) {
    border-radius: 50%;
    float: right;
    margin-left: 3rem;
    width: 250px;
  }

  @media(--breakpoint-20) {
    width: 350px;
  }
}

.c-media--video {
  height: 0;
  padding-bottom: 56.25%;
  width: 100%;
}

.c-media__video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
